import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/Layout'

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import forty_logo from '../img/40th_ann_logo.png'

export default class GivingPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Your Gift Matters</h1>
              <p>
              Your gift helps to build the future for women in engineering
              across the nation.
              </p>
              <p>
              Through the generosity of your donation, Phi Rhos are provided opportunities and access to transformative leadership experiences. They receive the life-changing encouragement of academic scholarships and leadership grants. With your help, Phi Sigma Rho invests in the advancement of STEM outreach to young girls and women pursuing interests in engineering.
              </p>
              <p>
              By giving to the Phi Sigma Rho Foundation, donors show they are personally committed to developing the Phi Sigma Rho sisterhood and overall achievement of women in engineering. Every gift, at any amount, supports educational and leadership opportunities offered to our sisters and the philanthropic efforts of Phi Sigma Rho.
              </p>

              <img src={forty_logo} style={{ float: 'left', margin: '0 20px 20px 0', maxWidth : '300px' }} />
              <h2 style={{ marginBottom: '24px', marginTop: '48px' }}>Campaign for Phi Sigma Rho: 40 for 40</h2>

              <p style={{ textAlign: 'justify', textIndent: '2em' }}>
              The Phi Sigma Rho Foundation, with the support of the Sorority, has embarked on a campaign to ensure the future of our 
              sisterhood is supported with its ever changing needs. With more than 10,000 sisters across the globe, 
              the Foundation strives to continue to expand and evolve to best support the Phi Sigma Rho community. In honor of 40 
              year of Phi Sigma Rho, our goal is to raise $40,000 by Founders&quot; Day, September 24, 2024. Build the future with
              us and make your donation today!
              </p>

              <a
                href="https://www.classy.org/campaign/Campaign-for-Phi-Sigma-Rho-40-for-40/c542957"
                className="button is-large is-rounded"
                style={{
                  fontFamily: 'Quicksand',
                  fontWeight: 'bold',
                  display: 'block',
                  color: '#8e2344',
                  backgroundColor: 'white',
                  border: '3px #8e2344 solid',
                  borderRadius: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '40%',
                  minWidth: '200px',
                }}
              >
                Donate to the 40 for 40 Campaign!
              </a>

              <h2 style={{ marginBottom: '24px', marginTop: '48px' }}>
                Ways to Give
              </h2>

              <p>
              The Phi Sigma Rho Foundation is exempt from federal income tax under Internal Revenue Code section 501(c)(3). Donations to the foundation are tax-deductible as provided by law.
              </p>

              <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>
                      One Time or Recurring Gift
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                      <h3>Give Online!</h3>
                      <p>
                        Giving online is quick, easy, and secure. You can make both
                        one-time and recurring gift, as well as pledges and endowments.
                      </p>
                      <br />
                      <button
                        className="button is-large is-rounded donate-btn"
                        style={{
                          fontFamily: 'Quicksand',
                          fontWeight: 'bold',
                          display: 'block',
                          color: '#8e2344',
                          backgroundColor: 'white',
                          border: '3px #8e2344 solid',
                          borderRadius: 0,
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width: '40%',
                          minWidth: '200px',
                        }}
                      >
                        Donate Now!
                      </button>

                      <br />

                      <h3>Give by Mail</h3>
                      <p>
                        To give a gift by mail, please send a check or money order to:<br />
                        Phi Sigma Rho Foundation, PO Box 7021, Westchester, IL 60154.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>
                      Matching Gifts
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                    <p>
                      Your employer, or your spouse's employer, may offer a gift
                      matching program, which could match, double or more to your
                      gift. Each company has its own guidelines for gift matching
                      programs.
                    </p>
                    <p>
                      If you have any questions, please contact the{' '}
                      <a
                        href="mailto:assistanttreasurer@phisigmarhofoundation.org"
                      >Foundation Corporate Giving Chair</a>.
                    </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion style={{ display: 'block' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>
                        Non-Cash Gifts
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                    <h3>Stock or Security Gift</h3>
                    <p>
                      Making a charitable donation in the form of stock shares rather
                      than cash can greatly benefit both the Phi Sigma Rho Foundation
                      and you. As a stock donor, the value of the donation can be
                      deducted as a charitable gift if you itemize deductions on
                      Schedule A, and you avoid paying the capital gains tax on the
                      appreciated stock! For the Foundation, a stock donation expands
                      and diversifies our investment portfolio and gives us the option
                      to use the stock for long-term growth or to sell the shares to
                      satisfy a more immediate financial need.
                    </p>
                    <p>
                      The Phi Sigma Rho Foundation maintains an investment account
                      established through Charles Schwab. If you would like to make a
                      donation of stock to the Foundation, please contact the{' '}
                      <a href="mailto:treasurer@phisigmarhofoundation.org">
                        Foundation Treasurer
                      </a>{' '}
                      to begin the process of transferring stock.
                    </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion style={{ display: 'block' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>
                      Planned Gifts
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                    <h3>Pledges and Endowments Gifts</h3>
                    <p>
                      Spreading out your payments on a large gift through a pledge—a
                      formal agreement to make a gift to the Foundation—is a wonderful
                      way to reach a more generous goal with regular payments over
                      time. What is more, each payment on your pledge is eligible for
                      an income-tax charitable deduction. Like all gifts, pledges can
                      be designated to a specific fund or program.
                    </p>
                    <p>
                      The Phi Sigma Rho Foundation also accepts endowment gifts.
                      Unlike gifts for current use, endowment gifts provide security
                      over the long term by expanding the Foundation’s permanent
                      financial base and creating a predictable source of future
                      income for such things as endowed scholarships.
                    </p>
                    <p>
                      Please contact the{' '}
                      <a href="mailto:donorengagement@phisigmarhofoundation.org">
                        Foundation Vice President of Donor Relations
                      </a>{' '}
                      for more information today!
                    </p>

                    <h3>Planned Giving through a Bequest</h3>
                    <h4 style={{ fontFamily: 'Quicksand' }}>How it works:</h4>
                    <p>
                      Include the Phi Sigma Rho Foundation (PO Box 7021, Westchester,
                      IL 60154) as a beneficiary in your estate plan. If desired,
                      direct how you would like the gift to be used, or, you can make
                      an unrestricted gift to support the Foundation, which will be
                      used to support the organization’s goals wherever the need is
                      greatest.
                    </p>
                    <p>
                      Please let us know that you’ve chosen to give to the Foundation
                      through a bequest; contact the{' '}
                      <a href="mailto:donorengagement@phisigmarhofoundation.org">
                        Foundation Vice President of Donor Relations
                      </a>{' '}
                      . We would love to say thank you for your support and
                      generosity!
                    </p>
                    <br />
                    <h4 style={{ fontFamily: 'Quicksand' }}>Benefits:</h4>
                    <ul>
                      <li>
                        Your assets remain in your control during your lifetime.
                      </li>
                      <li>
                        You can modify your bequest if your circumstances change.
                      </li>
                      <li>You can direct your bequest to a particular purpose.</li>
                      <li>
                        There is currently no limit on the estate tax deductions that
                        can be taken for charitable bequests.
                      </li>
                      <li>
                        You can have the satisfaction of knowing that your bequest
                        will benefit the sisters of Phi Sigma Rho, helping to sustain
                        the organization in the future.
                      </li>
                      <li>
                        If you have already decided to include the Phi Sigma Rho
                        Foundation in your will, let us know so that we can recognize
                        you through our lifetime giving program.
                      </li>
                      <li>
                        Information contained on this website is not a substitute for
                        professional counsel. Appropriate legal, tax, accounting
                        and/or financial assistance should be sought in connection
                        with any planned giving.
                      </li>
                    </ul>
                    </AccordionDetails>
                  </Accordion>
              <br />
              <a
                href="https://www.classy.org/give/461566/#!/donation/checkout"
                className="button is-large is-rounded"
                style={{
                  fontFamily: 'Quicksand',
                  fontWeight: 'bold',
                  display: 'block',
                  color: '#8e2344',
                  backgroundColor: 'white',
                  border: '3px #8e2344 solid',
                  borderRadius: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '40%',
                  minWidth: '200px',
                }}
              >
                Donate Now!
              </a>
              <p
                style={{
                  fontStyle: 'italic',
                  fontSize: 'small',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '40%',
                  minWidth: '200px',
                }}
              >
                Clicking the above link will redirect to you to the website for
                Classy. Classy is an online fundraising platform and payment
                processor used by the Phi Sigma Rho Foundation.
              </p>

              <a
                href="https://www.phisigmarhofoundation.org/donors"
                className="button is-large is-rounded"
                style={{
                  fontFamily: 'Quicksand',
                  fontWeight: 'bold',
                  display: 'block',
                  color: '#8e2344',
                  backgroundColor: 'white',
                  border: '3px #8e2344 solid',
                  borderRadius: 0,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '40%',
                  minWidth: '200px',
                }}
              >
                See Our Donors!
              </a>
              <br />

              <div>
                <h2
                  id="faq"
                  style={{
                    marginBottom: '24px',
                    marginTop: '48px',
                    textAlign: 'center',
                  }}
                >
                  Frequently Asked Questions
                </h2>
                <div className="container">
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>
                        Are my gifts to the Phi Sigma Rho Foundation tax
                        deductible?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                      <p>
                        Yes - The Phi Sigma Rho Foundation is recognized by the
                        IRS as a 501(c)(3) charitable organization, which means
                        that all gifts to the Foundation are tax-exempt to the
                        fullest extent allowed by law.
                      </p>
                      <p>
                        Our federal tax identification number is{' '}
                        <b>20-2709091</b>.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>
                        Can I write a check to the Phi Sigma Rho Foundation?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                      <p>
                        Yes - Please make your check out to the Phi Sigma Rho
                        Foundation. We are unable to process checks written to
                        other organizations.
                      </p>
                      <p>
                        If you would like to restrict your gift to a specific
                        fund or campaign, or if you are giving on behalf of a
                        Phi Sigma Rho chapter or alumnae organization, please
                        make note in the memo field on the check.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion style={{ display: 'block' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>
                        What does my donation support?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                      <p>
                        You can apply your donations to different Foundation
                        funds and programs by marking your gifts as unrestricted
                        or restricted.
                      </p>
                      <p>
                        <b>Unrestricted gifts</b> are among the most valuable to
                        the Foundation because they allow us to allocate the
                        funds wherever the need is greatest. These gifts to the
                        annual fund not only support the Foundation’s vital
                        programs, but also fund the Foundation needs to be able
                        to administer our vital programs.
                      </p>
                      <p>
                        <b>Restricted gifts</b> allow you to designate your gift
                        to a specific program, grant, scholarship or endowment.
                        When you designate your gift, you enjoy the satisfaction
                        of knowing you are supporting a program that is
                        personally meaningful to you.
                      </p>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion style={{ display: 'block' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <h3 style={{ margin: 0 }}>
                        What funds can I restrict my gift towards?
                      </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block' }}>
                      <p>There are seven funds that you can designate towards:</p>
                      <ul>
                        <li>
                          General Fund (unrestricted) - The General Fund allows
                          your gift to be used for the area of greatest need. As
                          an unrestricted gift, your donation can be used on all
                          Foundation operations or programming, from LEAP
                          sessions at convention or conference, or towards one
                          of our scholarships.
                        </li>
                        <li>
                          LEAP Fund (restricted) - This is the current area of
                          greatest need. LEAP stands for Leadership,
                          Educational, And Professional programming. This fund
                          covers the grants that fund professional speakers and
                          educational programming at conferences and
                          conventions, and with more support, LEAP could become
                          a recognized professional program. By donating towards
                          the LEAP fund, your gift impacts the most sisters
                          overall.
                        </li>
                        <li>
                          Scholarship Fund (restricted) - The Scholarship Fund
                          is a restricted for the support to any of our
                          scholarships needing funding, including the
                          Leadership, Merit, and Philanthropy and Service
                          scholarships. Specific funds may be created to finance
                          additional scholarships as we work towards endowing
                          each for our future, as is with:
                        </li>
                        <ul>
                          <li>
                            Sarah Rogers McClure Memorial Scholarship Fund
                            (restricted)
                          </li>
                          <li>
                            Francis J. Monigan Memorial Scholarship Fund
                            (restricted)
                          </li>
                          <li>
                            Anne Louise Roach Legacy Scholarship Fund
                            (restricted)
                          </li>
                        </ul>
                        <li>
                          Professional Services &amp; Paid Staff Fund
                          (restricted) - This fund supports Foundation
                          operations in the use of third party services, such as
                          lawyers, accountants, or marketing services. This fund
                          also supports future hopes to have paid staff members,
                          such as an executive director.
                        </li>
                        <li>
                          DreamGirls Grant Fund (restricted) - The DreamGirls Grant Fund supports DreamGirls in Action and
                          DreamDay educational programming events targeting elementary and middle school youth
                          hosted by Phi Sigma Rho chapters and alumnae organizations. This fund builds the next
                          generation of women and girls in engineering and STEM careers while shaping Phi Rhos into 
                          philanthropists and leaders.
                        </li>
                        <li>
                          EDGE Grants Fund (restricted) - The Engineering Development and Growth Experience (EDGE)
                          grant fund supports technical hands-on projects led by sisters of Phi Sigma Rho. Through these
                          experiences, Phi Sigma Rho collegiate members will grow their technical experties, leadership
                          mindset, financial competency, and project management skills prior to entering their profession.
                        </li>
                        <li>
                          Chapter Endowment Funds (restricted) - Created to support the local needs of chapters, these funds
                          support various grants for programming scholarships for a specific group or region. These funds are
                          guided by a group of alumnae advisors who determine the appropriate allocation of funds.<br />
                          <br />
                          The Houston Alumnae Organization Chapter is currently the only existing fund. Their fund supports
                          specific scholarships for all of the Texas Phi Sigma Rho chapters.
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
            <br />
            <p>
              <Link to="/">← Go Home</Link>
            </p>
          </div>
        </section>
      </Layout>
    )
  }
}
